import { connect } from "react-redux";

import Locations from './locations';
import { fetchConfig, fetchLocations } from "../../../actions/config_actions";

const mSTP = (state, ownProps) => {
  return {
    config: state.config,
    appointments: state.appointments,
    locations: state.locations
  };
};

const mDTP = dispatch => ({
  getConfig: clientId => dispatch(fetchConfig(clientId)),
  getLocations: clientId => dispatch(fetchLocations(clientId))
});

export default connect(mSTP, mDTP)(Locations);
