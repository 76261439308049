import { connect } from "react-redux";
import { fetchAppointments } from "../../../actions/appointment_actions";
import AppointmentOptionsTwo from './appointment_options_two';

const mSTP = (state, ownProps) => {
  return {
    config: state.config,
    appointments: state.appointments
  };
};

const mDTP = dispatch => ({
  getAppointments: clientId => dispatch(fetchAppointments(clientId))
});

export default connect(mSTP, mDTP)(AppointmentOptionsTwo);
