import {
  RECEIVE_FIRST_AVAILABLE
} from '../actions/first_available_actions';

const firstAvailableReducer = (state = {}, action) => {
  Object.freeze(state)
  switch(action.type) {
    case RECEIVE_FIRST_AVAILABLE:
      return action.first_available;
    default:
      return state;
  }
};

export default firstAvailableReducer;
