import { connect } from "react-redux";
import { sendVerification } from "../../../actions/verify_actions";
import { createAppointment } from "../../../actions/appointment_actions";
import ScheduleAppointment from './schedule_appointment';

const mSTP = (state, ownProps) => {
  return {
    config: state.config,
    appointments: state.appointments,
    first_available: state.first_available,
    verify: state.verify
  };
};

const mDTP = dispatch => ({
  sendVerification: data => dispatch(sendVerification(data)),
  createAppointment: data => dispatch(createAppointment(data))
});

export default connect(mSTP, mDTP)(ScheduleAppointment);
