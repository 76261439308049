import {
  RECEIVE_APPOINTMENTS,
  RECEIVE_APPOINTMENT
} from '../actions/appointment_actions';

const appointmentsReducer = (state = {}, action) => {
  Object.freeze(state)
  switch(action.type) {
    case RECEIVE_APPOINTMENTS:
      return action.appointments;
    case RECEIVE_APPOINTMENT:
      return action.appointment;
    default:
      return state;
  }
};

export default appointmentsReducer;
