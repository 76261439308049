import { connect } from "react-redux";
import { fetchFirstAvailable } from "../../../actions/first_available_actions";
import FirstAppointment from './first_appointment';

const mSTP = (state, ownProps) => {
  return {
    config: state.config,
    appointments: state.appointments,
    first_available: state.first_available
  };
};

const mDTP = dispatch => ({
  fetchFirstAvailable: data => dispatch(fetchFirstAvailable(data))
});

export default connect(mSTP, mDTP)(FirstAppointment);
