import * as FirstAvailableAPIUtil from '../util/first_available_api_util';

export const RECEIVE_FIRST_AVAILABLE = "RECEIVE_FIRST_AVAILABLE";

const receiveFirstAvailable = first_available => ({
  type: RECEIVE_FIRST_AVAILABLE,
  first_available
});

export const fetchFirstAvailable = data => dispatch => {
  return FirstAvailableAPIUtil.fetchFirstAvailable(data)
  .then(first_available => {
    dispatch(receiveFirstAvailable(first_available.data));
  })
  .catch(error => {
    if (error.response && error.response.status) {
      dispatch(receiveFirstAvailable(error.response.status))
    } else {
      dispatch(receiveFirstAvailable("NA"))
    }
  });
};
