import { connect } from "react-redux";
import { fetchAppointments, fetchAllAppointments } from "../../../actions/appointment_actions";
import Calendar from './calendar';

const mSTP = (state, ownProps) => {
  return {
    config: state.config,
    appointments: state.appointments
  };
};

const mDTP = dispatch => ({
  getAppointments: clientId => dispatch(fetchAppointments(clientId)),
  getAllAppointments: clientId => dispatch(fetchAllAppointments(clientId))
});

export default connect(mSTP, mDTP)(Calendar);
