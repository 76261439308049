import * as VerifyAPIUtil from '../util/verify_api_util';

export const RECEIVE_VERIFICATION = "RECEIVE_VERIFICATION";
export const SEND_VERIFICATION = "SEND_VERIFICATION";

const receiveVerification = verify => ({
  type: RECEIVE_VERIFICATION,
  verify
});

const receiveSendVerification = verify => ({
  type: SEND_VERIFICATION,
  verify
});

export const checkVerification = data => dispatch => {
  return VerifyAPIUtil.checkVerification(data)
  .then(verification => {
    dispatch(receiveVerification(verification.data));
  })
  .catch(error => {
    if (error.response && error.response.status) {
      dispatch(receiveVerification(error.response.status));
    } else {
      dispatch(receiveVerification("error"))
    }
  });
};

export const sendVerification = data => dispatch => {
  return VerifyAPIUtil.sendVerification(data)
  .then(verification => {
    dispatch(receiveSendVerification(verification.data));
  })
  .catch(error => {
    if (error.response && error.response.status) {
      dispatch(receiveVerification(error.response.status));
    } else {
      dispatch(receiveVerification("error"))
    }
  });
};
