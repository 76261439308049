import React from "react";
import "./sidebar.scss";
import $ from "jquery";
import defaultLogo from '../../images/loadingImage.png';
import { withRouter } from 'react-router';
import { getContrast } from '../styles/contrast';
import PropTypes from 'prop-types';
import backIcon from '../../images/arrow-circle-left-solid.svg';
import { hexToRgb } from '../styles/css-filter';

class SideBar extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);

    this.handleChangeAppt = this.handleChangeAppt.bind(this);
  }

  componentDidMount() {
    const sidebar = document.getElementsByClassName("provider");

    if (this.props.config.practicePhotoUrl !== null) {
      $(sidebar).css({'background-image': `url(${this.props.config.practicePhotoUrl}`, 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover', "filter": "blur(2.0px)", 'background-color':"#707070"});
      $(sidebar).addClass("overlay");

    } else {
      $(sidebar).removeClass("overlay");
      $(sidebar).attr('style',  `background-color:${ this.props.config.color ? this.props.config.color : "black" }`);
    }

    const providerImageBar = document.getElementsByClassName("providerImageSideBar");

    if (window.innerWidth < 480) {
      $(providerImageBar).attr('style', `width: none`);
    } else {
      $(providerImageBar).attr('style', `width: auto !important`);
    }
  }

  addDefaultSrc(e){
    e.target.src = defaultLogo;
  }

  componentDidUpdate() {
    const providerImageBar = document.getElementsByClassName("providerImageSideBar");
    const logo = document.getElementsByClassName("logo-radius");

    if (window.innerWidth < 480) {
      $(providerImageBar).attr('style', `width: none`);
    } else {
      $(providerImageBar).attr('style', `width: auto !important`);
    }

    $(logo).attr('style', `width: 100% !important`);
  }

  handleChangeAppt(e) {
    e.preventDefault();

    if (parseInt(window.sessionStorage.currentPage) === 9 && !!JSON.parse(window.sessionStorage.backButton)) {
      window.sessionStorage.setItem("currentPage", 3);
      window.sessionStorage.setItem("backButton", JSON.stringify(false));
      let split = this.props.location.pathname.split('/');
      let string;

      if (split.length > 3) {
        string = `/${split[1]}/${split[2]}`;
      } else {
        string = `/${split[1]}`;
      }

      this.props.history.push(`${string}/4`);
    } else {
      let split = this.props.location.pathname.split('/');
      let string;

      if (split.length > 3) {
        string = `/${split[1]}/${split[2]}`;
      } else {
        string = `/${split[1]}`;
      }

      if ( parseInt(window.sessionStorage.backPage) === 6 || parseInt(window.sessionStorage.backPage) === 7) {
        window.sessionStorage.setItem("currentPage", (parseInt(window.sessionStorage.backPage) - 1));
        this.props.history.push(`${string}/${window.sessionStorage.backPage}`);
        window.sessionStorage.setItem("backPage", 0);
      } else if ( parseInt(window.sessionStorage.currentPage) === 1) {
        this.props.history.push(`${string}/`);
      } else if ( parseInt(window.sessionStorage.backPage) === 2 && parseInt(window.sessionStorage.currentPage) === 4) {
        window.sessionStorage.setItem("currentPage", 1);
        this.props.history.push(`${string}/2`);
        window.sessionStorage.removeItem("backPage");
      } else if ( parseInt(window.sessionStorage.backPage) === 0 && parseInt(window.sessionStorage.currentPage) === 4) {
        window.sessionStorage.setItem("currentPage", 1);
        this.props.history.push(`${string}/2`);
      } else if ( parseInt(window.sessionStorage.backPage) === 0 && parseInt(window.sessionStorage.currentPage) === 5) {
        window.sessionStorage.setItem("currentPage", 1);
        this.props.history.push(`${string}/2`);
      } else if ( parseInt(window.sessionStorage.backPage) === 2 && parseInt(window.sessionStorage.currentPage) === 5) {
        window.sessionStorage.setItem("currentPage", 1);
        this.props.history.push(`${string}/2`);
        window.sessionStorage.removeItem("backPage");
      } else if ( parseInt(window.sessionStorage.backPage) === 3 && parseInt(window.sessionStorage.currentPage) === 5) {
        window.sessionStorage.setItem("currentPage", 2);
        this.props.history.push(`${string}/3`);
      } else if ( parseInt(window.sessionStorage.backPage) === 5 && parseInt(window.sessionStorage.currentPage) === 8) {
        window.sessionStorage.setItem("currentPage", 4);
        this.props.history.push(`${string}/5`);
        window.sessionStorage.removeItem("backPage");
      } else if (window.sessionStorage.backPage === undefined && parseInt(window.sessionStorage.currentPage) === 5) {
        window.sessionStorage.setItem("currentPage", 2);
        this.props.history.push(`${string}/3`);
        window.sessionStorage.removeItem("backPage");
      } else {
        if (window.sessionStorage.backPage === undefined && parseInt(window.sessionStorage.currentPage) > 3) {
          window.sessionStorage.setItem("currentPage", parseInt(window.sessionStorage.currentPage) - 1);

          this.props.history.push(`${string}/${window.sessionStorage.currentPage}`);
          window.sessionStorage.removeItem("backPage");
        } else {
          window.sessionStorage.setItem("currentPage", (parseInt(window.sessionStorage.currentPage) - 1));

          this.props.history.push(`${string}/${window.sessionStorage.currentPage}`);
        }
      }
    }
  }

  render() {
    const contrastColor = this.props.config.color ? this.props.config.color : "#2699fb";

    const sidebar = document.getElementsByClassName("provider");

    let style;
    let logoStyle = {};

    let providers = this.props.config.providers;
    let providerIndex;
    let provider;
    let providerImage;
    let providerName;
    let appointmentIndex;
    let appointment;

    if (this.props.config.appointments) {
      appointmentIndex = this.props.config.appointments.findIndex(appointment => parseInt(appointment.appointment_id) === parseInt(window.sessionStorage.appointmentId));
      appointment = this.props.config.appointments[appointmentIndex];
    }

    if (providers && window.sessionStorage.providerId) {
      providerIndex = this.props.config.providers.findIndex(provider => parseInt(provider.provider_assoc) === parseInt(window.sessionStorage.providerId));
      provider = this.props.config.providers[providerIndex];

      if (provider && provider.pictureURI !== null && appointment && appointment.provider_select !== 0) {
          providerImage = <img alt="providerImage" className="providerImageSideBar border-radius" src={provider.pictureURI}/>;
      } else {
        if (this.props.config.logoUrl && this.props.config.logoUrl !== "") {
          providerImage = <img alt="provImg" className="logo logo-radius" src={this.props.config.logoUrl}/>;
        } else {
          providerImage = null;
        }
      }
    } else {
      providerImage = this.props.config.logoUrl ? <img alt="provImg" className="logo logo-radius" src={this.props.config.logoUrl}/> : null;
    }

    if (this.props.config.practicePhotoUrl !== null) {
      $(sidebar).css({'background-image': `url(${this.props.config.practicePhotoUrl}`, 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover', "filter": "blur(1.2px)"});
      $(sidebar).addClass("overlay");

      if (provider !== undefined && appointment.provider_select !== 0) {
        style = {
          color: 	"#585858",
          display: "block",
        }
      } else {
        logoStyle = {
          height: "100%"
        }

        style = {
          display: "none"
        }
      }
    } else {
      $(sidebar).removeClass("overlay");
      $(sidebar).attr('style',  `background-color:${ this.props.config.color ? this.props.config.color : "black" }`);

      if (provider !== undefined && appointment.provider_select !== 0) {

        style = {
          color: getContrast(contrastColor),
          display: "block"
        }
      } else {
        logoStyle = {
          height: "100%"
        }

        style = {
          display: "none"
        }
      }
    }

    if (provider !== undefined) {
      if (appointment && appointment.provider_select !== 0) {
        providerName = `${!!provider.name ? provider.name : "Provider Name Here"}`;
      }
    }

    const rgb = hexToRgb(this.props.config.color ? this.props.config.color : "#d0d0d0");
    if (rgb.length !== 3) {
      alert('Invalid format!');
      return;
    }

    let backButton;

    if (window.location !== window.parent.location) {
        backButton = <button onClick={this.handleChangeAppt} className="backButton btn btn-circle"><img height="55" width="55" className="backButtonSvg" src={backIcon} alt="backIcon"/></button>;
    }

    const providerImageBar = document.getElementsByClassName("providerImageSideBar");

    if (window.innerWidth < 480) {
      $(providerImageBar).attr('style', `width: none`);
    } else {
      $(providerImageBar).attr('style', `width: auto !important`);
    }

    const logo = document.getElementsByClassName("logo-radius");
    $(logo).attr('style', `width: 100% !important`);

    return (
      <div className="providerHead">
        <div className="floatLeft">
          {backButton}
        </div>
        <div className="provider"></div>

        <div className="providerContainer">
          <div className="prContainer row justify-content-center">
            <div style={logoStyle} className="logoWrapper col col-12">
              {providerImage}
            </div>
            <div style={style} className="col col-12 providerName">
              {providerName}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SideBar);
