import { connect } from "react-redux";

import SideBar from './sidebar';

const mSTP = (state, ownProps) => {
  return {
    config: state.config,
    appointments: state.appointments
  };
};

const mDTP = dispatch => ({
});

export default connect(mSTP, mDTP)(SideBar);
