import {
  RECEIVE_VERIFICATION,
  SEND_VERIFICATION
} from '../actions/verify_actions';

const verifyReducer = (state = {}, action) => {
  Object.freeze(state)
  switch(action.type) {
    case RECEIVE_VERIFICATION:
      return action.verify;
      case SEND_VERIFICATION:
        return action.verify;
    default:
      return state;
  }
};

export default verifyReducer;
