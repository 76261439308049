import * as ConfigAPIUtil from '../util/config_api_util';

export const RECEIVE_CONFIG = "RECEIVE_CONFIG";
export const RECEIVE_LOCATIONS = "RECEIVE_LOCATIONS";

const receiveConfig = config => ({
  type: RECEIVE_CONFIG,
  config
});

const receiveLocations = locations => ({
  type: RECEIVE_LOCATIONS,
  locations
});

export const fetchConfig = clientId => dispatch => {
  return ConfigAPIUtil.fetchConfig(clientId)
  .then(config => {
    if (!config.data.color) config.data.color='#146cae'
    window.sessionStorage.setItem("config", JSON.stringify(config.data));
    dispatch(receiveConfig(config.data))
  })
  .catch(error => {
    if (error.response && error.response.status) {
      dispatch(receiveConfig(error.response.status))
    } else {
      dispatch(receiveConfig("NA"))
    }
  });
};

export const fetchLocations = clientId => dispatch => {
  return ConfigAPIUtil.fetchLocations(clientId)
  .then(locations => {
    dispatch(receiveLocations(locations.data.mapping))
  })
  .catch(error => {
    if (error.response && error.response.status) {
      dispatch(receiveLocations(error.response.status))
    } else {
      dispatch(receiveLocations("NA"))
    }
  });
};
