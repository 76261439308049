import { combineReducers } from "redux";

import first_available from "./first_available_reducer";
import appointments from "./appointments_reducer";
import config from "./config_reducer";
import verify from "./verify_reducer";
import locations from "./locations_reducer";

const RootReducer = combineReducers({
  first_available,
  appointments,
  config,
  verify,
  locations
});

export default RootReducer;
