import React from 'react';

import BraceIcon from '../../images/brace.svg';
import ChippedIcon from '../../images/chipped.svg';
import CrackIcon from '../../images/crack.svg';
import ImplantIcon from '../../images/implant.svg';
import InstrumentsIcon from '../../images/instruments.svg';
import PlusIcon from '../../images/plus.svg';
import ShineIcon from '../../images/shine.svg';
import StarIcon from '../../images/star.svg';
import ToothyIcon from '../../images/tooth.svg';

import Sunrise from '../../images/sunrise.svg';
import Sun from '../../images/sun-full.svg';
import Moon from '../../images/moon.svg';

import MapMarker from '../../images/map-marker-alt-solid.svg';
import CalendarIcon from '../../images/calendar-alt-solid.svg';

class ToothIcon extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    }
  }

  render() {
    const images = [ToothyIcon, InstrumentsIcon, PlusIcon, StarIcon, BraceIcon, ImplantIcon, CrackIcon, ChippedIcon, ShineIcon, MapMarker, CalendarIcon, Sunrise, Sun, Moon];

    let iconStyle;


    if (this.props.icon < 12) {
      iconStyle = {
        filter: window.sessionStorage.iconColor ? JSON.parse(window.sessionStorage.iconColor) : "#2699fb"
      };
    }

    let height;
    let width;

    if (this.props.height) {
      height = this.props.height;
    } else {
      height = "45"
    }

    if (this.props.width) {
      width = this.props.width;
    } else {
      width = "45"
    }

    return (
        <img height={height} width={width} src={images[this.props.icon - 1]} style={iconStyle} className={this.props.class} alt="toothIcon" />
    );
  }
}

export default ToothIcon;
