import * as AppointmentAPIUtil from '../util/appointment_api_util';

export const RECEIVE_APPOINTMENTS = "RECEIVE_APPOINTMENTS";
export const RECEIVE_APPOINTMENT = "RECEIVE_APPOINTMENT";

const receiveAppointments = appointments => ({
  type: RECEIVE_APPOINTMENTS,
  appointments
});

const receiveAppointment = appointment => ({
  type: RECEIVE_APPOINTMENT,
  appointment
});

export const createAppointment = (data) => dispatch => {
  return AppointmentAPIUtil.createAppointment(data)
  .then(create => {
    dispatch(receiveAppointment(create.data))
  })
  .catch(error => {
    if (error.response && error.response.status) {
      window.sessionStorage.setItem("appointments", error.response.status);
      dispatch(receiveAppointments(error.response.status));
    } else {
      window.sessionStorage.setItem("appointments", "NA");
      dispatch(receiveAppointments("NA"))
    }
  });
}

export const fetchAppointments = (data) => dispatch => {
  return AppointmentAPIUtil.fetchAppointments(data)
  .then(appointments => {
    window.sessionStorage.setItem("appointments", JSON.stringify(appointments.data));
    dispatch(receiveAppointments(appointments.data));
  })
  .catch(error => {
    if (error.response && error.response.status) {
      window.sessionStorage.setItem("appointments", error.response.status);
      dispatch(receiveAppointments(error.response.status));
    } else {
      window.sessionStorage.setItem("appointments", "NA");
      dispatch(receiveAppointments("NA"))
    }
  });
};

export const fetchAllAppointments = (data) => dispatch => {
  return AppointmentAPIUtil.fetchAllAppointments(data)
  .then(appointments => {
    window.sessionStorage.setItem("appointments", JSON.stringify(appointments.data));
    dispatch(receiveAppointments(appointments.data));
  })
  .catch(error => {
    if (error.response && error.response.status) {
      window.sessionStorage.setItem("appointments", error.response.status);
      dispatch(receiveAppointments(error.response.status));
    } else {
      window.sessionStorage.setItem("appointments", "NA");
      dispatch(receiveAppointments("NA"))
    }
  });
};
