import React from "react";
import styled from 'styled-components';
import './footer.scss';
import { getContrast } from '../styles/contrast';

class Footer extends React.Component {
  render() {
    const contrastColor = this.props.config.color ? this.props.config.color : "#C2C2C2";

    const Footer = styled.div`
      background-color: ${contrastColor} !important;
      color: ${getContrast(contrastColor)}
    `;

    return (
      <Footer className="footerContainer">
        <div className="row justify-content-center">
          <div className="footerAddress">
            {this.props.config.address}
          </div>
        </div>
      </Footer>
    );
  }
}

export default Footer;
