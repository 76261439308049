import { connect } from "react-redux";

import PatientType from './patient_type';
import { fetchConfig } from "../../../actions/config_actions";

const mSTP = (state, ownProps) => {
  return {
    config: state.config,
    appointments: state.appointments
  };
};

const mDTP = dispatch => ({
  getConfig: clientId => dispatch(fetchConfig(clientId))
});

export default connect(mSTP, mDTP)(PatientType);
