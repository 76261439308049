import { connect } from "react-redux";
import { createAppointment } from "../../../actions/appointment_actions";
import CreateAppointment from './create_appointment';

const mSTP = (state, ownProps) => {
  return {
    config: state.config,
    appointments: state.appointments,
    first_available: state.first_available
  };
};

const mDTP = dispatch => ({
  createAppointment: data => dispatch(createAppointment(data))
});

export default connect(mSTP, mDTP)(CreateAppointment);
